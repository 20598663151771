<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Home" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="First section title"
                  v-model="first_firstSectionTitle"
                  :error="!!errors.home_first_section_title"
                  :error-messages="errors.home_first_section_title"
                  @input="delete errors.home_first_section_title"
                />
                <va-input
                  label="First string"
                  v-model="first_firstString"
                  :error="!!errors.home_first_first_string"
                  :error-messages="errors.home_first_first_string"
                  @input="delete errors.home_first_first_string"
                />
                <va-input
                  label="Second string"
                  v-model="first_secondString"
                  :error="!!errors.home_first_second_string"
                  :error-messages="errors.home_first_second_string"
                  @input="delete errors.home_first_second_string"
                />
                <va-input
                  label="Third string"
                  v-model="first_thirdString"
                  :error="!!errors.home_first_third_string"
                  :error-messages="errors.home_first_third_string"
                  @input="delete errors.home_first_third_string"
                />
                <va-input
                  type="textarea"
                  label="Description"
                  v-model="first_description"
                  :error="!!errors.home_first_description"
                  :error-messages="errors.home_first_description"
                  @input="delete errors.home_first_description"
                />
                <va-input
                  label="Image Alt"
                  v-model="first_imageAlt"
                  :error="!!errors.home_first_img_alt"
                  :error-messages="errors.home_first_img_alt"
                  @input="delete errors.home_first_img_alt"
                />
                <va-input
                  label="Image Title"
                  v-model="first_imageTitle"
                  :error="!!errors.home_first_img_title"
                  :error-messages="errors.home_first_img_title"
                  @input="delete errors.home_first_img_title"
                />
                <va-input
                  label="Button Label"
                  v-model="first_buttonLabel"
                  :error="!!errors.home_first_btn_label"
                  :error-messages="errors.home_first_btn_label"
                  @input="delete errors.home_first_btn_label"
                />
                <va-input
                  label="Button Link"
                  v-model="first_buttonLink"
                  :error="!!errors.home_first_btn_link"
                  :error-messages="errors.home_first_btn_link"
                  @input="delete errors.home_first_btn_link"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Section title"
                  v-model="second_sectionTitle"
                  :error="!!errors.home_second_section_title"
                  :error-messages="errors.home_second_section_title"
                  @input="delete errors.home_second_section_title"
                />
                <va-input
                  label="First string"
                  v-model="second_firstString"
                  :error="!!errors.home_second_first_string"
                  :error-messages="errors.home_second_first_string"
                  @input="delete errors.home_second_first_string"
                />
                <va-input
                  label="Second string"
                  v-model="second_secondString"
                  :error="!!errors.home_second_second_string"
                  :error-messages="errors.home_second_second_string"
                  @input="delete errors.home_second_second_string"
                />
                <va-input
                  label="Third string"
                  v-model="second_thirdString"
                  :error="!!errors.home_second_third_string"
                  :error-messages="errors.home_second_third_string"
                  @input="delete errors.home_second_third_string"
                />
                <va-input
                  type="textarea"
                  label="Description"
                  v-model="second_description"
                  :error="!!errors.home_second_description"
                  :error-messages="errors.home_second_description"
                  @input="delete errors.home_second_description"
                />
                <va-input
                  label="Image Alt"
                  v-model="second_imageAlt"
                  :error="!!errors.home_second_first_img_alt"
                  :error-messages="errors.home_second_first_img_alt"
                  @input="delete errors.home_second_first_img_alt"
                />
                <va-input
                  label="Image Title"
                  v-model="second_imageTitle"
                  :error="!!errors.home_second_img_title"
                  :error-messages="errors.home_second_img_title"
                  @input="delete errors.home_second_img_title"
                />
                <va-input
                  label="Button Label"
                  v-model="second_buttonLabel"
                  :error="!!errors.home_second_btn_label"
                  :error-messages="errors.home_second_btn_label"
                  @input="delete errors.home_second_btn_label"
                />
                <va-input
                  label="Button Link"
                  v-model="second_buttonLink"
                  :error="!!errors.home_second_btn_link"
                  :error-messages="errors.home_second_btn_link"
                  @input="delete errors.home_second_btn_link"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Section title"
                  v-model="third_sectionTitle"
                  :error="!!errors.home_third_section_title"
                  :error-messages="errors.home_third_section_title"
                  @input="delete errors.home_third_section_title"
                />
                <va-input
                  label="First string"
                  v-model="third_firstString"
                  :error="!!errors.home_third_first_string"
                  :error-messages="errors.home_third_first_string"
                  @input="delete errors.home_third_first_string"
                />
                <va-input
                  label="Second string"
                  v-model="third_secondString"
                  :error="!!errors.home_third_second_string"
                  :error-messages="errors.home_third_second_string"
                  @input="delete errors.home_third_second_string"
                />
                <va-input
                  label="Third string"
                  v-model="third_thirdString"
                  :error="!!errors.home_third_third_string"
                  :error-messages="errors.home_third_third_string"
                  @input="delete errors.home_third_third_string"
                />
                <va-input
                  type="textarea"
                  label="Description"
                  v-model="third_description"
                  :error="!!errors.home_third_description"
                  :error-messages="errors.home_third_description"
                  @input="delete errors.home_third_description"
                />
                <va-input
                  label="Image Alt"
                  v-model="third_imageAlt"
                  :error="!!errors.home_third_first_img_alt"
                  :error-messages="errors.home_third_first_img_alt"
                  @input="delete errors.home_third_first_img_alt"
                />
                <va-input
                  label="Image Title"
                  v-model="third_imageTitle"
                  :error="!!errors.home_third_img_title"
                  :error-messages="errors.home_third_img_title"
                  @input="delete errors.home_third_img_title"
                />
                <va-input
                  label="Button Label"
                  v-model="third_buttonLabel"
                  :error="!!errors.home_third_btn_label"
                  :error-messages="errors.home_third_btn_label"
                  @input="delete errors.home_third_btn_label"
                />
                <va-input
                  label="Button Link"
                  v-model="third_buttonLink"
                  :error="!!errors.home_third_btn_link"
                  :error-messages="errors.home_third_btn_link"
                  @input="delete errors.home_third_btn_link"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                  :error="!!errors.home_seo_title"
                  :error-messages="errors.home_seo_title"
                  @input="delete errors.home_seo_title"
                />
                <va-input
                  label="Meta Description"
                  type="textarea"
                  v-model="metaDescription"
                  :error="!!errors.home_seo_description"
                  :error-messages="errors.home_seo_description"
                  @input="delete errors.home_seo_description"
                />
                <va-input
                  label="Seo Title"
                  v-model="seoTitle"
                  :error="!!errors.home_seo_seo_title"
                  :error-messages="errors.home_seo_seo_title"
                  @input="delete errors.home_seo_seo_title"
                />
                <div style="margin-bottom: 15px;">
                  <span>Seo Text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.home_seo_text" style="color: red;">{{ errors.home_seo_text[0] }}</div>
                </div>
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                  :error="!!errors.home_seo_og_title"
                  :error-messages="errors.home_seo_og_title"
                  @input="delete errors.home_seo_og_title"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                  :error="!!errors.home_seo_og_description"
                  :error-messages="errors.home_seo_og_description"
                  @input="delete errors.home_seo_og_description"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteImage(seoOpenGraphImageId, 'home_seo_og_image') ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.home_seo_og_image"
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('home_seo_og_image', 'home_seo_og_image', seoOpenGraphImage[0])">Завантажити</va-button>
                  <div v-if="errors.home_seo_og_image" style="color: red;">{{ errors.home_seo_og_image[0] }}</div>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      first_firstSectionTitle: '',
      first_firstString: '',
      first_secondString: '',
      first_thirdString: '',
      first_description: '',
      first_imageAlt: '',
      first_imageTitle: '',
      first_buttonLabel: '',
      first_buttonLink: '',

      second_sectionTitle: '',
      second_firstString: '',
      second_secondString: '',
      second_thirdString: '',
      second_description: '',
      second_imageAlt: '',
      second_imageTitle: '',
      second_buttonLabel: '',
      second_buttonLink: '',

      third_sectionTitle: '',
      third_firstString: '',
      third_secondString: '',
      third_thirdString: '',
      third_description: '',
      third_imageAlt: '',
      third_imageTitle: '',
      third_buttonLabel: '',
      third_buttonLink: '',

      metaTitle: '',
      metaDescription: '',
      seoTitle: '',
      seoText: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_SHKIL_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['First screen', 'Second screen', 'Third screen', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        home_first_section_title: this.first_firstSectionTitle,
        home_first_first_string: this.first_firstString,
        home_first_second_string: this.first_secondString,
        home_first_third_string: this.first_thirdString,
        home_first_description: this.first_description,
        home_first_img_alt: this.first_imageAlt,
        home_first_img_title: this.first_imageTitle,
        home_first_btn_label: this.first_buttonLabel,
        home_first_btn_link: this.first_buttonLink,

        home_second_section_title: this.second_sectionTitle,
        home_second_first_string: this.second_firstString,
        home_second_second_string: this.second_secondString,
        home_second_third_string: this.second_thirdString,
        home_second_description: this.second_description,
        home_second_img_alt: this.second_imageAlt,
        home_second_img_title: this.second_imageTitle,
        home_second_btn_label: this.second_buttonLabel,
        home_second_btn_link: this.second_buttonLink,

        home_third_section_title: this.third_sectionTitle,
        home_third_first_string: this.third_firstString,
        home_third_second_string: this.third_secondString,
        home_third_third_string: this.third_thirdString,
        home_third_description: this.third_description,
        home_third_img_alt: this.third_imageAlt,
        home_third_img_title: this.third_imageTitle,
        home_third_btn_label: this.third_buttonLabel,
        home_third_btn_link: this.third_buttonLink,

        home_seo_title: this.metaTitle,
        home_seo_description: this.metaDescription,
        home_seo_seo_title: this.seoTitle,
        home_seo_text: this.seoText,
        home_seo_og_title: this.seoOpenGraphTitle,
        home_seo_og_description: this.seoOpenGraphDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file, agg = 'home') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('aggregate', agg)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/upload/4?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/4/delete-file/${id}?lang=${this.locale}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/4?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/validate/4?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/4?lang=${this.locale}`)
        .then(response => {
          this.first_firstSectionTitle = response.data.home_first_section_title
          this.first_firstString = response.data.home_first_first_string
          this.first_secondString = response.data.home_first_second_string
          this.first_thirdString = response.data.home_first_third_string
          this.first_description = response.data.home_first_description
          this.first_imageAlt = response.data.home_first_img_alt
          this.first_imageTitle = response.data.home_first_img_title
          this.first_buttonLabel = response.data.home_first_btn_label
          this.first_buttonLink = response.data.home_first_btn_link

          this.second_sectionTitle = response.data.home_second_section_title
          this.second_firstString = response.data.home_second_first_string
          this.second_secondString = response.data.home_second_second_string
          this.second_thirdString = response.data.home_second_third_string
          this.second_description = response.data.home_second_description
          this.second_imageAlt = response.data.home_second_img_alt
          this.second_imageTitle = response.data.home_second_img_title
          this.second_buttonLabel = response.data.home_second_btn_label
          this.second_buttonLink = response.data.home_second_btn_link

          this.third_sectionTitle = response.data.home_third_section_title
          this.third_firstString = response.data.home_third_first_string
          this.third_secondString = response.data.home_third_second_string
          this.third_thirdString = response.data.home_third_third_string
          this.third_description = response.data.home_third_description
          this.third_imageAlt = response.data.home_third_img_alt
          this.third_imageTitle = response.data.home_third_img_title
          this.third_buttonLabel = response.data.home_third_btn_label
          this.third_buttonLink = response.data.home_third_btn_link

          this.metaTitle = response.data.home_seo_title ? response.data.home_seo_title : ''
          this.metaDescription = response.data.home_seo_description ? response.data.home_seo_description : ''
          this.seoTitle = response.data.home_seo_seo_title ? response.data.home_seo_seo_title : ''
          this.seoText = response.data.home_seo_text ? response.data.home_seo_text : ''
          this.seoOpenGraphTitle = response.data.home_seo_og_title ? response.data.home_seo_og_title : ''
          this.seoOpenGraphDescription = response.data.home_seo_og_description ? response.data.home_seo_og_description : ''
          this.seoOpenGraphImageExisting = response.data.home_seo_og_image ? process.env.VUE_APP_SHKIL_ADMIN + response.data.home_seo_og_image : null
          this.seoOpenGraphImageId = response.data.home_seo_og_image_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
